export const GLOBALS_ROUTE = '/c/remote-globals.json';
export const NAVIGATION_ROUTE = '/c/json-navigation.json';

export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/login?register=true';
export const DASHBOARD_ROUTE = '/e/account/dashboard';
export const WISHLIST_ROUTE = '/e/account/wishlist';
export const MY_APPLIANCES_ROUTE = '/e/account/appliances';
export const LAST_TRANSACTIONS_ROUTE = '/e/account/transactions';
export const PROFILE_ROUTE = '/e/account/profile';
export const CHECKOUT_ROUTE = '/e/checkout';
export const CART_ROUTE = '/e/cart';

export const AUTH_SESSION_PATH = 'session';
export const AUTH_LOGOUT_PATH = 'session/logout';
export const HYBRIS_SESSION_PATH = '/e/authentication/status';
export const HYBRIS_LOGOUT_PATH = '/e/logout';