import Cookies from 'js-cookie';
import { countryCodeLanguageMap, domainsByCountryCode } from '@/constants/domains';
import { GLOBALS_ROUTE } from '@/constants/routes.constants';
import type { MieleStoredGlobals } from '@/types/shared-types';

export const getAnonymousCartId = async (siteId: string): Promise<string | null> => {
    const cookieKey = `${siteId}-cart`;
    return Cookies.get(cookieKey) || null;
};

export const getSessionGlobals = (): MieleStoredGlobals | null => {
    const globals = sessionStorage.getItem('miele_globals');
    if (globals) return JSON.parse(globals);
    return null;
};

export const getSiteGlobals = async (): Promise<MieleStoredGlobals | null> => {
    const mieleSessionGlobals = getSessionGlobals();

    if (mieleSessionGlobals) return mieleSessionGlobals;

    const fetchUrl = handleMultilingualPrefix(GLOBALS_ROUTE);

    let mieleGlobals: MieleStoredGlobals | undefined;
    await fetch(fetchUrl)
        .then((response) => response.json())
        .then((data) => {
            mieleGlobals = data;
            sessionStorage.setItem('miele_globals', JSON.stringify(mieleGlobals));
        })
        .catch((e) => {
            console.error('Unable to load VG globals data.', e);
        });
    return mieleGlobals || null;
};

// import from: "experience-api/hybrisUtils.ts"
export const isQweb = (domain: string) => {
    const domainAsString = domain.toString() ?? '';
    return Boolean(domainAsString.match(/^qweb\./));
};

export const isAllowedDomain = (domain: string) => {
    const domainAsString = domain.toString() ?? '';
    return Boolean(domainAsString.match(/(dweb|qweb|miele|mieleusa)\./));
};

//check if project is USA
export const isUSADomain = (domain: string) => {
    const domainAsString = domain.toString() ?? '';
    return Boolean(domainAsString.match(/(mieleusa)\./));
};

/**
 * Check if domain supports multi-language
 * @param domain string
 */
export const isMultiLingualDomain = (domain: string) => {
    const countryCode = getDomainCountryCode(domain);
    const multiLangCountry = findMultiLangCountry(countryCode);

    return Boolean(multiLangCountry);
};

// get language value from URL if project is multilangual
export const getDomainLanguage = (domain: string) => {
    const languageCandidate = domain?.split('/')[3];
    const countryCode = getDomainCountryCode(domain);
    const multiLang = findMultiLangCountry(countryCode);

    return multiLang?.languages.includes(languageCandidate) ? languageCandidate : multiLang?.languages[0] || undefined;
};

// get fallback language value if URL do not have it
export const getFallbackLanguage = (domain: string) => {
    const countryCode = getDomainCountryCode(domain);
    const match = findMultiLangCountry(countryCode);
    // TODO: check if it makes sense to pull the language in the globals config

    return match?.languages[0] || undefined;
};

// get country code based on existing domain list in constants
export const getDomainCountryCode = (domain: string) => {
    try {
        const host = new URL(domain).host;
        const foundCountry = Object.entries(domainsByCountryCode).find(([, domain]) => host.includes(domain));

        return foundCountry?.[0]; // country code
    } catch (err) {
        if ((err as Error).message.includes('Invalid URL:')) {
            return undefined;
        } else {
            throw err;
        }
    }
};

/**
 * Finds multi-lang configuration for given country code
 * @param countryCode string
 */
export const findMultiLangCountry = (countryCode?: string) =>
    countryCodeLanguageMap.find((country) => country.countryCode === countryCode?.toLowerCase());

/**
 * handleMultilingualPrefix
 * check if project is multilangual
 * get language param from URL
 * return updated value
 * @param path
 * @returns
 */
export const handleMultilingualPrefix = (path: string): string => {
    let updatedPath = path.startsWith('/') ? path : `/${path}`;
    if (isMultiLingualDomain(window.location.href)) {
        const domainLanguage = getDomainLanguage(window.location.href) || '';
        updatedPath = `/${domainLanguage}${updatedPath}`;
    }

    return updatedPath;
};
